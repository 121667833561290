import Text from '@atoms/Text';
import LazyImage from '@atoms/LazyImage';
import Image from '@atoms/Image';
import texts from '@components/Homepage/en.json';

function Opportunities({
    minHeight = 'min-h-[580px] md:min-h-[420px] xl:min-h-[620px]',
    paddingTop = 'pt-20 md:pt-[68px] px-6',
    isCustomBannerVisible,
    bannerCarouselActive,
}) {
    return (
        <section
            className={`relative flex flex-col justify-center ${
                isCustomBannerVisible
                    ? 'min-h-[450px] md:min-h-[380px] xl:min-h-[620px]'
                    : bannerCarouselActive
                    ? 'min-h-[300px] md:min-h-[380px] xl:min-h-[472px] !justify-start md:!justify-center mt-6 md:mt-0'
                    : minHeight
            } max-w-screen-maxScreen mx-auto`}
        >
            <LazyImage
                src="/images/homepage/opportunity-graph.svg"
                blurDataURL="/images/homepage/home-page-banner-v3.png"
                className="absolute top-0 bottom-0 left-0 right-0 w-full h-full opportunity-graph"
                imgClassName="object-contain md:object-cover !overflow-visible max-h-none "
            />
            <div id="opportunitiesSection" className='relative -top-20'/>
            <div
                className={`mx-auto ${
                    isCustomBannerVisible
                        ? 'py-10 md:py-10 px-6'
                        : bannerCarouselActive
                        ? 'px-6 '
                        : paddingTop
                }`}
            >
                <Text
                    content={texts?.OpportunitiesSectionHeading}
                    className="h5-regular md:h3-regular text-primary-500 text-center"
                    isInnerHtml={true}
                    htmlTag="h2"
                />
                <Text
                    content={texts?.OpportunitiesSectionDescription}
                    className="p5-regular md:p4-regular text-gray-900 text-center mt-3"
                    isInnerHtml={true}
                />
            </div>
            <Image
                src="/images/homepage/opportunity-growth-glow.svg"
                className={`absolute right-0 ${
                    isCustomBannerVisible ? 'bottom-6' : 'bottom-20'
                } md:hidden block`}
            />
        </section>
    );
}

export default Opportunities;
